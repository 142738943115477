import React from "react";
import {SkillI} from "../../interfaces/app.interface";
import {useLoader} from "../../hooks/useLoader";

interface SkillProp {
    skill: SkillI;
}

const Skill = ({skill}: SkillProp) => {

    const {loading} =  useLoader();

    return (
        <div className="col-6 col-md-4 col-lg-2 mb-2 pointer-cursor animate__animated animate__fadeInDown">
            <div
                className="card h-100 bg-dark-2 zoom-card"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={skill.tags}
            >
                <img
                    src={skill.url}
                    className="card-img-top hvr-glow animate__animated animate__fadeIn"
                    alt={skill.name}
                    hidden={loading}
                />
                <div className="card-footer text-white text-center">
                    {skill.name}
                </div>
            </div>
        </div>
    );
};

export default Skill;
