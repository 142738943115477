import React from "react";
import {ProjectI} from "../../interfaces/app.interface";
import {useLoader} from "../../hooks/useLoader";

interface PropsPr {
    project: ProjectI;
}

const Project = ({project}: PropsPr) => {

    const {loading} =  useLoader();

    const templateCode =
        project.code === "" ? null : (
            <a
                className="btn btn-primary"
                href={project.code}
                target="_blank"
                rel="noopener noreferrer"
            >
                Code
            </a>
        );
    return (
        <div className="col pointer-cursor animate__animated animate__fadeInDown">
            <div className="card h-100 bg-dark-2 zoom-card">
                <img
                    src={project.image1}
                    className="card-img-top img-fluid animate__animated animate__fadeIn"
                    alt={project.name}
                    data-bs-toggle="modal"
                    data-bs-target={`#modal${project.id}`}
                    hidden={loading}
                />
                <div className="card-body">
                    <h5 className="card-text text-center text-primary font-text">{project.name}</h5>
                    <p className="card-text text-white">{project.description}</p>
                </div>
                <div className="card-footer d-flex justify-content-end">
                    <button
                        type="button"
                        className="btn btn-primary mx-1"
                        data-bs-toggle="modal"
                        data-bs-target={`#modal${project.id}`}
                    >
                        View
                    </button>
                    <a
                        className="btn btn-primary mx-1"
                        href={project.demo}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Demo
                    </a>
                    {templateCode}
                </div>
            </div>
        </div>
    );
};

export default Project;
