import React, {useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import appContext from "./../context/app/appContext";

const Header = () => {

    const history = useHistory();

    const {changeLangFn, isLoading, applicationData: {items}} = useContext(appContext);

    const [active, setActive] = useState("");

    const {pathname} = useLocation();

    const [lang, setLang] = useState("Español");

    const [profileExt, setProfileExt] = useState("");


    useEffect(() => {
        window.scrollTo(0, 0);
        if (pathname === "/") {
            setActive("/about");
            return;
        }
        setActive(pathname);
    }, [pathname]);

    useEffect(() => {
        const random = getRandom();
        if (random > 9) {
            setProfileExt('jpg');
        } else {
            setProfileExt('png');
        }
    }, [])

    const redirect = (href: string) => {
        history.push(`/${href}`);
    };

    const handleLan = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const isChecked = e.target["checked"];
        if (isChecked) {
            setLang("English");
            changeLangFn("en");
        } else {
            setLang("Español");
            changeLangFn("es");
        }
    };

    const getRandom = () => {
        return Math.floor((Math.random() * (10 - 1 + 1)) + 1);
    }


    return (
        <nav
            className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top border-right"
            id="sideNav"
        >
            <a className="navbar-brand js-scroll-trigger" href="#">
                <span className="d-block d-lg-none">Kevin Catucuamba</span>
                <span className="d-none d-lg-block">
         {profileExt !== "" && <img
             className="img-fluid img-profile rounded-circle mx-auto mb-2 hvr-grow"
             src={"./assets/app/profile.jpeg"}
             alt="Profile"
         />}
        </span>
            </a>

                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarResponsive"
                        aria-controls="navbarResponsive"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav animate__animated animate__fadeInDown">
                    <li className="nav-item">
                        <button
                            className={`btn btn-link nav-link hvr-forward js-scroll-trigger mx-auto 
              ${active === "/about" ? "active" : ""} `}
                            onClick={() => redirect("about")}
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                            disabled={isLoading}
                        >
                            {items[0].value}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`btn btn-link nav-link hvr-forward mx-auto 
              ${active === "/portfolio" ? "active" : ""} `}
                            onClick={() => redirect("portfolio")}
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                            disabled={isLoading}
                        >
                            {items[1].value}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`btn btn-link nav-link hvr-forward mx-auto 
              ${active === "/skills" ? "active" : ""} `}
                            onClick={() => redirect("skills")}
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                            disabled={isLoading}
                        >
                            {items[2].value}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`btn btn-link nav-link hvr-forward mx-auto 
              ${active === "/articles" ? "active" : ""} `}
                            onClick={() => redirect("articles")}
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                            disabled={isLoading}
                        >
                            {items[3].value}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`btn btn-link nav-link hvr-forward mx-auto 
              ${active === "/contact" ? "active" : ""} `}
                            onClick={() => redirect("contact")}
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                            disabled={isLoading}
                        >
                            {items[4].value}
                        </button>
                    </li>
                    <li className="nav-item mt-4">
                        <input
                            type="checkbox"
                            className="btn-check"
                            id="btn-check-outlined"
                            autoComplete="off"
                            onClick={handleLan}
                            disabled={isLoading}
                        />
                        <label className="btn btn-primary" htmlFor="btn-check-outlined">
                            {/* <i className="fa fa-globe mx-2" aria-hidden="true"></i> */}
                            {lang}
                        </label>
                        <br/>
                    </li>
                </ul>
            </div>


        </nav>
    );
};

export default Header;
