import React, {useEffect, useReducer} from "react";
import {ApplicationData, IContextApp, Message} from "../../../interfaces/app.interface";
import appContext from "./appContext";
import data from "../../../../data/original/data.json";
import dataeng from "../../../../data/original/dataeng.json";
import appReducer from "./appReducer";
import {axiosDevTo} from "../../../axios/axiosDevTo";
import {HttpService} from "../../../services/httpService";

const AppState = (props: any) => {

    const defaultState: IContextApp = {
        // about: data.about,
        // author: data.author,
        // projects: data.projects,
        // skills: data.skills,
        // items: data.items,
        // contact: data.contact,
        // title: data.title,
        // words: data.words,
        applicationData: {
            skills: [],
            title: {
                skill: "",
                portfolio: "",
                contact: "",
                modalSuccess: "",
                modal: "",
                article: "",
            },
            items: [
                {value: "Acerca de mí"},
                {value: "Porfafolio"},
                {value: "Habilidades"},
                {value: "Artículos"},
                {value: "Contacto"}
            ],
            contact: {
                placeholder2: "",
                label2: "",
                placeholder1: "",
                label1: "",
                helpText: "",
                btnText: ""
            },
            projects: [],
            about: {
                cvUrl: "",
                description: ""
            },
            words: [],
            articles: [],
            author: {
                phone: "",
                linkedin: "",
                github: "",
                email: "",
                email2: "",
                country: "",
                address: "",
                name: "",
                facebook: "",
                lastname: "",
                whatsapp: ""
            },
        },
        firstRender: true,
        isLoading: true,
        sendMessageFn: () => {},
        changeLangFn: () => {},
        setFirstRenderFn: () => {}
    };
    //Dispatch for ejecute actions
    const [state, dispatch] = useReducer(appReducer, defaultState);


    useEffect(() => {

        changeLangFn("es").then();

    }, []);

    const sendMessageFn = async (message: Message) => {
        try {
            await HttpService.sendMessage(message);
        } catch (error) {
            console.log(error);
        }
    };

    const changeLangFn = async (lang: string) => {
        dispatch({
            type: "SET_LOADING",
            payload: true,
        })
        const data = await HttpService.getData(lang);
        dispatch({
            type: "CHANGE_LANG",
            payload: data,
        });
    };

    const setFirstRenderFn = () => {
        dispatch({
            type: "SET_FIRST_RENDER",
            payload: false,
        });
    };

    return (
        <appContext.Provider
            value={{
                applicationData: state.applicationData,
                firstRender: state.firstRender,
                isLoading: state.isLoading,
                sendMessageFn,
                changeLangFn,
                setFirstRenderFn
            }}
        >
            {props.children}
        </appContext.Provider>
    );
};

export default AppState;
