import {axiosDevTo} from "../axios/axiosDevTo";
import {axiosFirestore} from "../axios/axiosFirestore";
import {ApplicationData, ArticleI, Message} from "../interfaces/app.interface";
import {Util} from "../util/util";

export class HttpService {

    static async getArticlesFromDevTo(): Promise<ArticleI[]> {
        const httpResponse = await axiosDevTo.get("articles?username=kcatucuamba");
        return httpResponse.data;
    }

    static async getData(lang: string): Promise<ApplicationData> {

        const axiosResponseFirestore = await axiosFirestore.get(`cv-data/${lang}`)
        const axiosResponseDevTo = await axiosDevTo.get("articles?username=kcatucuamba");
        const applicationData = Util.mapData(axiosResponseFirestore.data);
        applicationData.articles = axiosResponseDevTo.data;
        return applicationData;
    }

    static async sendMessage({message, email}: Message) {
        const response = await axiosFirestore.post(
            "messages",
            {
                fields: {
                    message: {stringValue: message},
                    email: {stringValue: email},
                }
            }
        );

        console.log(response);


        return response.status === 200 || response.status === 201;
    }

}
