import {useEffect, useState} from "react";


export const useLoader = (time = 1100) => {

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, time);
        return () => {
            clearTimeout(timeout);
        }
    }, []);

    return {loading};


}
