import React, {useContext} from "react";
import appContext from "../../components/context/app/appContext";
import Skill from "./Skill";

const Skills = () => {
    const {applicationData: {skills, title}} = useContext(appContext);

    return (
        <section className="resume-section mx-auto" id="skills">
            <div className="resume-section-content">
                <h2 className="mb-5 animate__animated animate__fadeInDown gradient-text">
                    {title.skill}
                </h2>
                <div className="row">
                    {skills.map((skill) => (
                        <Skill key={skill.name} skill={skill}></Skill>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Skills;
