

export class Constants {

    static DEVTO_URL = "https://dev.to/api/";
    static DATA_URL= "https://firestore.googleapis.com/v1/projects/product-hunt-ec576/databases/(default)/documents/"

    //static DATA_URL= "http://localhost:4010/porfolio/"

}
