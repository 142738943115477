import Header from "./components/partials/Header";
import About from "./pages/about/About";
import Portfolio from "./pages/project/Portfolio";
import Form from "./pages/form/Form";
import Skills from "./pages/skill/Skills";
import Articles from "./pages/article/Articles";


import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import "./hover-min.css";
import "animate.css";
import React, {useContext, useEffect} from "react";
import {LogsService} from "./services/logsService";
import Footer from "./components/partials/Footer";
import appContext from "./components/context/app/appContext";


function App() {
    const {isLoading} = useContext(appContext);

    useEffect(() => {
        LogsService.newLog().then()
    }, [])


    return (
        <Router>
            <Header />
            <div id="main-container" className="container-fluid p-0 bg-dark">
                {isLoading ? (
                    <section className="resume-section mx-auto">
                        <span className="loader mx-auto"></span>
                    </section>
                ) : (
                    <>
                    <Switch>
                        <Route exact path="/" component={About} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/portfolio" component={Portfolio} />
                        <Route exact path="/skills" component={Skills} />
                        <Route exact path="/contact" component={Form} />
                        <Route exact path="/articles" component={Articles} />
                        {/*  Route not exist  */}
                          <Route path="*">
                                <h1 className="font-text-danger text-center my-auto py-5">404 Not Found</h1>
                            </Route>
                    </Switch>
                    <Footer />
                    </>
                )}


            </div>
        </Router>
    );
}

export default App;
