const Footer = () => {

    return (
        <div className="container bg-dark mb-2">
            <footer className="text-white text-center text-lg-start bg-dark">
                <div className="text-center p-1 bg-dark">
                    © {new Date().getFullYear()}{'  Copyright:'}
                    <a className="text-white" href="https://mdbootstrap.com/">kcatucuamba.com</a>
                </div>
            </footer>

        </div>
    )
}

export default Footer