import React, { useContext, useEffect, useState } from "react";
import { Typewriter } from "react-simple-typewriter";
import appContext from "../../components/context/app/appContext";
import { LogsService } from "../../services/logsService";

const About = () => {
    const { applicationData: { author, words, about }, firstRender, setFirstRenderFn }
        = useContext(appContext);

    const [cursor, setCursor] = useState(true);

    useEffect(() => {
        const getData = async () => {
            const data = await LogsService.getDataES();
        }
        getData().then();
    }, []);

    const onLoopDone = () => {
        setCursor(false);
        setFirstRenderFn();
    };

    return (
        <section className="resume-section mx-auto" id="about">
            <div className="resume-section-content">
                <div className="h2 mb-0 animate__animated animate__fadeInDown text-white">
                    {author.name}
                    <span className="text-primary mx-1 gradient-text">{author.lastname}</span>
                </div>
    
                <h3 className="mb-0 my-2 d-flex align-items-center animate__animated animate__fadeInDown text-white">
                    <span id="typewritter" className="text-primary">
                        {/* Style will be inherited from the parent element */}
                        {firstRender ? <Typewriter
                            words={words}
                            loop={1}
                            cursor={cursor}
                            cursorStyle="_"
                            typeSpeed={100}
                            deleteSpeed={50}
                            delaySpeed={1000}
                            onLoopDone={onLoopDone}
                        /> : <>{words[words.length - 1]}</>
                        }
                    </span>
                    {/*{*/}
                    {/*    !cursor ? <img*/}
                    {/*        className='mx-2 animate__animated animate__fadeInDown'*/}
                    {/*        src={'https://images.emojiterra.com/twitter/512px/1f1ea-1f1e8.png'}*/}
                    {/*        alt={'Ecuador'}*/}
                    {/*        style={{width: '35px', height: '35px'}}*/}
                    {/*    /> : <></>*/}
                    {/*}*/}
                </h3>
                <div className="card-body"></div>
                <p className="lead mb-4 mt-3 animate__animated animate__fadeInDown text-white text-justify">
                    {about.description}
                </p>
                <p className="lead mb-5 mt-1 animate__animated animate__fadeInDown text-white">
                    <ul>
                        <li><a
                            target="_blank" href={author.linkedin}>linkedin.com/in/kdcatucuambal/</a>
                        </li>
                        <li><a
                            target="_blank" href={author.github}>github.com/kdcatucuambal</a>
                        </li>
                        <li><a
                            target="_blank" href={about.cvUrl}>cv/kdcatucuambal.pdf</a>
                        </li>
                    </ul>
                </p>

            </div>
        </section>
    );
};

export default About;
