import React, {useContext} from "react";
import appContext from "../../components/context/app/appContext";
import Pr from "./Project";
import Modal from "../../components/Modal";

const Portfolio = () => {
    const {applicationData: {projects, title}} = useContext(appContext);
    return (
        <section className="resume-section mx-auto" id="portfolio">
            <div className="resume-section-content">
                <h2 className="mb-5 animate__animated animate__fadeInDown gradient-text">{title.portfolio}</h2>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {projects.map((project) => {
                        return project.isActive ? <Pr key={project.id} project={project}/> : null;
                    })}
                </div>
            </div>
            {projects.map((project) => (
                <Modal key={project.id} id={project.id} image={project.image1}></Modal>
            ))}
        </section>
    );
};

export default Portfolio;
