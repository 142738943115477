import React from 'react';
import {ArticleI} from "../../interfaces/app.interface";
import {useLoader} from "../../hooks/useLoader";

interface ArticleProp {
    article: ArticleI;
}


const Article = ({article}: ArticleProp) => {

    const {loading} =  useLoader();

    return (
        <div className="col pointer-cursor animate__animated animate__fadeInDown">
            <div className="card h-100 bg-dark-2 zoom-card">
                <img
                    src={article.cover_image ? article.cover_image : 'https://group.skanska.com/4a0b9e/globalassets/common-items/placeholdernoimage.svg'}
                    className="card-img-top img-fluid animate__animated animate__fadeIn"
                    alt={article.title}
                    hidden={loading}

                />
                <div className="card-body">
                    <h5 className="card-text text-center text-primary font-text">{article.title}</h5>
                    <p className="card-text text-white">{article.description}</p>
                </div>
                <div className="card-footer d-flex justify-content-end">
                <a
                        className="btn btn-primary mx-1"
                        href={article.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        View
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Article;
