import React, {useContext, useEffect, useState} from "react";
import { ArticleI } from "../../interfaces/app.interface";
import { HttpService } from "../../services/httpService";
import Article from "./Article";
import appContext from "../../components/context/app/appContext";


const Articles = () => {

    const [articles, setArticles] = useState<ArticleI[]>([]);

    const {applicationData: {title}} = useContext(appContext)

    useEffect(() => {
        const getArticles = async () => {
            try {
                const articles = await HttpService.getArticlesFromDevTo();
                setArticles(articles);
            } catch (e) {
                console.log(e);
            } finally {
                //console.log("Finally");
            }
        }
        getArticles().then();
    }, []);
    return (
        <section className="resume-section mx-auto" id="portfolio">
                <div className="resume-section-content">
                    <h2 className="mb-5 animate__animated animate__fadeInDown gradient-text">{title.article}</h2>
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        {articles.map((article) => (
                            <Article key={article.id} article={article}></Article>
                        ))}
                    </div>
                </div>
        </section>
    );
};

export default Articles;
