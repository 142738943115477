import Bowser from "bowser"
import ipAddress from "public-ip"

export interface ClientInfoProps {
    ip: string,
    time: string,
    date: any,
    engine: string,
    os: string,
    platform: string
}

export class LogsService {

    static async newLog() {
        const clientInfo: ClientInfoProps = {
            ip: "",
            time: "",
            date: null,
            engine: "",
            os: "",
            platform: ""
        };

        const date = new Date();
        const bowerResponse = Bowser.getParser(window.navigator.userAgent).getResult();

        clientInfo.ip = await ipAddress.v4();
        clientInfo.os = bowerResponse.os.name + ' - ' + bowerResponse.os.version + ' - '
            + bowerResponse.os.versionName;
        clientInfo.date = date;
        clientInfo.engine = bowerResponse.engine.name + ' - ' + bowerResponse.engine.version;
        clientInfo.platform = bowerResponse.platform.type + ' - ' + bowerResponse.platform.model + ' - '
            + bowerResponse.platform.vendor + '';
        clientInfo.time = date.toLocaleTimeString();
        //await firebaseDB.collection("visit_logs").add(clientInfo);
    }

    static async getDataES(){
        //const querySnapshot = await firebaseDB.collection("usuarios").get();
        //return querySnapshot.docs.map(doc => doc.data());
    }


    static async getRandomMessage() {
        const random = "1221";
    }
}
