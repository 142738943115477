import React, {useContext, useState} from "react";
import appContext from "../../components/context/app/appContext";
import {Message} from "../../interfaces/app.interface";

const Form = () => {
    const {sendMessageFn, applicationData: {title, contact}} = useContext(appContext);
    const [data, setData] = useState<Message>({
        email: "",
        message: "",
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const {email, message} = data;
        if (email.trim() === "" || message.trim() === "") {
            const btn = document.getElementById("sende");
            btn.click();
            return;
        }
        sendMessageFn(data);
        const btn = document.getElementById("sendm");
        btn.click();
        setData({email: "", message: ""});
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <section className="resume-section mx-auto" id="contact">
            <div className="resume-section-content">
                <h2 className="mb-5 text-center animate__animated animate__fadeInDown gradient-text">
                    {title.contact}
                </h2>
                <div className="col-12 col-md-6 mx-auto">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3 animate__animated animate__fadeInDown">
                            <label htmlFor="exampleInputEmail1" className="form-label text-white">
                                {contact.label1}
                            </label>
                            <input
                                type="email"
                                name="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder={contact.placeholder1}
                                onChange={handleChange}
                                value={data.email}
                            />
                            <div id="emailHelp" className="form-text text-white">
                                {contact.helpText}
                            </div>
                        </div>
                        <div className="mb-3 animate__animated animate__fadeInDown">
                            <label htmlFor="message" className="form-label text-white">
                                {contact.label2}
                            </label>

                            <textarea
                                name="message"
                                id="message"
                                className="form-control"
                                rows={5}
                                placeholder={contact.placeholder2}
                                onChange={handleChange}
                                value={data.message}
                            ></textarea>
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary btn-block animate__animated animate__fadeInDown"
                        >
                            {contact.btnText}
                        </button>
                    </form>
                </div>
            </div>
            <button
                id="sende"
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#modalError"
                hidden
            ></button>
            <button
                id="sendm"
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#modalSuccess"
                hidden
            ></button>
            <div
                className="modal fade"
                id="modalError"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {title.contact}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <h3 className="font-text-danger">
                                <i
                                    className="fa fa-exclamation-triangle mx-2"
                                    aria-hidden="true"
                                ></i>
                                {title.modal}
                            </h3>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="modalSuccess"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {title.contact}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <h3 className="font-text">
                                <i className="fa fa-check mx-2" aria-hidden="true"></i>
                                {title.modalSuccess}
                            </h3>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Form;
