import {Reducer} from "react";
import {IContextApp, ReducerAction} from "../../../interfaces/app.interface";

const AppReducer: Reducer<IContextApp, ReducerAction> = (state, action) => {

    switch (action.type) {
        case "SET_LOADING":
            return {...state, isLoading: action.payload};
        case "CHANGE_LANG":
            console.log("CHANGE_LANG");
            console.log(action.payload);
            return {...state, applicationData: action.payload, isLoading: false};
        case "SET_FIRST_RENDER":
            return {...state, firstRender: action.payload};
        default:
            return {...state};
    }

};

export default AppReducer;
