import React from "react";

interface ModalProps {
    id: string;
    image: string;
}

const Modal = ({id, image}: ModalProps) => {
    return (
        <div
            className="modal fade"
            id={`modal${id}`}
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="modal-body p-0 m-0 text-center">
                        <img src={image} alt="" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
