import {
    AboutI,
    ApplicationData,
    AuthorI,
    Contact,
    CvRes,
    ProjectI,
    SkillI,
    Item,
    Title
} from "../interfaces/app.interface";


export class Util {

    static mapData(data: CvRes): ApplicationData{
        const authorRes = data.fields['author']["mapValue"]["fields"];
        const author: AuthorI = {
            name: authorRes['name']["stringValue"],
            address: authorRes['address']["stringValue"],
            country: authorRes['country']["stringValue"],
            email: authorRes['email']["stringValue"],
            email2: authorRes['email2']["stringValue"],
            facebook: authorRes['facebook']["stringValue"],
            github: authorRes['github']["stringValue"],
            lastname: authorRes['lastname']["stringValue"],
            linkedin: authorRes['linkedin']["stringValue"],
            phone: authorRes['phone']["stringValue"],
            whatsapp: authorRes['whatsapp']["stringValue"],
        }

        const projectsRes = data.fields['projects']["arrayValue"]["values"];
        const projects: ProjectI[] = projectsRes.map((project: any): ProjectI => {
            const projectFields = project["mapValue"]["fields"];
            return {
                id: projectFields['id']["stringValue"],
                code: projectFields['code']["stringValue"],
                demo: projectFields['demo']["stringValue"],
                description: projectFields['description']["stringValue"],
                image1: projectFields['image1']["stringValue"],
                image2: projectFields['image2']["stringValue"],
                name: projectFields['name']["stringValue"],
                isActive: projectFields['isActive']["booleanValue"],
            }
        });

        const wordsRes = data.fields['words']["arrayValue"]["values"];
        const words: string[] = wordsRes.map((word: any): string => {
            return word["stringValue"];
        });

        const titleRes = data.fields['title']["mapValue"]["fields"];
        const title: Title = {
            skill: titleRes['skill']["stringValue"],
            contact: titleRes['contact']["stringValue"],
            modal: titleRes['modal']["stringValue"],
            modalSuccess: titleRes['modalSuccess']["stringValue"],
            portfolio: titleRes['portfolio']["stringValue"],
            article: titleRes['article']["stringValue"]
        }

        const skillsRes = data.fields['skills']["arrayValue"]["values"];
        const skills: SkillI[] = skillsRes.map((skill: any): SkillI => {
            const skillFields = skill["mapValue"]["fields"];
            return {
                name: skillFields['name']["stringValue"],
                tags: skillFields['tags']["stringValue"],
                url: skillFields['url']["stringValue"],
            }
        });

        const contactRes = data.fields['contact']["mapValue"]["fields"];
        const contact: Contact = {
            btnText: contactRes['btnText']["stringValue"],
            helpText: contactRes['helpText']["stringValue"],
            label1: contactRes['label1']["stringValue"],
            label2: contactRes['label2']["stringValue"],
            placeholder1: contactRes['placeholder1']["stringValue"],
            placeholder2: contactRes['placeholder2']["stringValue"]
        }

        const aboutRes = data.fields['about']["mapValue"]["fields"];
        const about: AboutI = {
            cvUrl: aboutRes['cvUrl']["stringValue"],
            description: aboutRes['description']["stringValue"],
        }

        const itemsRes = data.fields['items']["arrayValue"]["values"];
        const items: Item[] = itemsRes.map((item: any): Item => {
            const itemFields = item["mapValue"]["fields"];
            return {
                value: itemFields['value']["stringValue"],
            }
        });



        return {
            about: about,
            author: author,
            contact: contact,
            items: items,
            projects: projects,
            skills: skills,
            title: title,
            words: words,
            articles: []
        };
    }


}
